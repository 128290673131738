import { useState, useEffect } from "react";
import "../App.css";
import "../App";
import ContactBar from "../Components/ContactBar";
import Header from "../Components/Header";
import useFadeIn from "../hooks/useFadeIn";
import { Achievements as AchievementsModel } from "../models/achievements";
import { listAchievements } from "../services/achievements";
import { useNavigate } from "react-router";

function Works() {
  const navigation = useNavigate();
  const [achievements, setAchievements] = useState<AchievementsModel[]>([]);
  const animation1 = "animation1";
  const active1 = "active1";
  const [active1Class, setActive1Class] = useFadeIn(animation1, 1.2);

  useEffect(() => {
    const fetchAchievements = async () => {
      const res = await listAchievements();
      setAchievements(res.data);
    };
    fetchAchievements();
  }, []);
  return (
    <>
      <div id="container">
        <Header />

        <div className="videotop phone_top">
          <h1 className="child_copy">Works</h1>
        </div>

        <main>
          <div className="child_top">
            <div className="bar_top_line"></div>
            <img
              className="Child_top"
              src="images/works_childTop.png"
              alt="camera"
            />
          </div>

          <div>
            <div className="bar_top_line"></div>
            <p className="bar">
              <a href="./">Top</a>&emsp;&gt;&emsp;
              <a href="./works">Works</a>
            </p>
          </div>

          <div className={"white_back_child_works"}>
            <div
              id="works"
              className={
                active1Class.has("works")
                  ? animation1 + " " + active1
                  : animation1
              }
            >
              {achievements.map((item, index) => {
                const id = String(item.create_datetime);
                if ((index - 1) % 3 === 0) {
                  return (
                    <div
                      id={id}
                      className={
                        active1Class.has(id)
                          ? "works_boxText" + " " + animation1 + " " + active1
                          : "works_boxText" + " " + animation1
                      }
                      key={item.create_datetime}
                    >
                      <span
                        onClick={() =>
                          navigation(`/works/${item.create_datetime}`)
                        }
                        className="worksImg"
                      >
                        <img src={item.thumbnail} alt={item.headline}></img>
                        <p className="worksHead">{item.headline}</p>
                      </span>
                      <div className="works_bar"></div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      id={id}
                      className={
                        active1Class.has(id)
                          ? "works_boxText" + " " + animation1 + " " + active1
                          : "works_boxText" + " " + animation1
                      }
                      key={item.create_datetime}
                    >
                      <span
                        onClick={() =>
                          navigation(`/works/${item.create_datetime}`)
                        }
                        className="worksImg"
                      >
                        <img src={item.thumbnail} alt={item.headline}></img>
                        <p className="worksHead">{item.headline}</p>
                      </span>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <ContactBar />
        </main>

        <footer>
          <small>
            Copyright&copy; <a href="./">SAMPLE COMPANY</a> All Rights Reserved.
          </small>
          <span className="pr">
            <a href="https://template-party.com/" target="_blank">
              《Web Design:Template-Party》
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}
export default Works;
