import "../App.css";
import "../App";
import { useEffect, useState } from "react";
import ScrollTop from "../hooks/ScrollTop";
import { useLocation } from "react-router";

// export type Props = {
//   backgroundColor: string;
// };

const Header: React.FC = () => {
  const [BackgroundColor, setBackgroundColor] = useState<string>("transparent");
  const location = useLocation();
  const ChangeHeader = () => {
    if (location.pathname === "/") {
      const position = ScrollTop();
      const targetTop = document.querySelectorAll(".videotop");
      targetTop.forEach((item) => {
        const h = item.getBoundingClientRect().height;
        if (h <= position) {
          setBackgroundColor("#1a1a1a");
        } else {
          setBackgroundColor("transparent");
        }
      });
    } else {
      setBackgroundColor("#1a1a1a");
    }
  };
  useEffect(() => {
    ChangeHeader();
    document.addEventListener("scroll", ChangeHeader);
    return (): void => {
      document.removeEventListener("scroll", ChangeHeader);
    };
  }, []);

  return (
    <>
      <header style={{ backgroundColor: BackgroundColor }}>
        <h1 id="logo">
          <a href="../">
            <img src="../images/logo_W.png" alt="moversロゴ" />
          </a>
        </h1>

        <input type="checkbox" id="checkbox" className="checkbox" />
        <label htmlFor="checkbox" className="hamburger">
          <span className="bar bar-top"></span>
          <span className="bar bar-middle"></span>
          <span className="bar bar-bottom"></span>
        </label>

        <nav id="menubar">
          <ul>
            <li>
              <a href="../">Top</a>
            </li>
            <li>
              <a href="../about">About</a>
            </li>
            <li>
              <a href="../our-business">Our business</a>
            </li>
            <li>
              <a href="../works">Works</a>
            </li>
            <li>
              <a href="../topics">Topics</a>
            </li>
            <li>
              <a href="../contact">Contact</a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
