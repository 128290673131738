import "../App.css";
import "../App";
import { Achievements as AchievementsModel } from "../models/achievements";
import { listAchievements } from "../services/achievements";
import { useNavigate } from "react-router";
import { memo, useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import { mediaQuery, useMediaQuery } from "../hooks/mediaQuery";

const SlideShow = memo((props) => {
  const navigation = useNavigate();
  const [achievements, setAchievements] = useState<AchievementsModel[]>([]);
  const isPhone = useMediaQuery(mediaQuery.phone);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchAchievements = async () => {
      const res = await listAchievements();
      setAchievements(res.data);
      setLoaded(true);
    };
    fetchAchievements();
  }, []);
  let options = {};
  if (isPhone) {
    options = {
      autoplay: true,
      type: "loop",
      speed: 2500,
      perPage: 3,
      perMove: 1,
      gap: "2vw",
      interval: 3500,
      arrows: false,
      isNavigation: true,
      resetProgress: false,
      classes: {
        pagination: "splide__pagination paginationPosition",
        page: "splide__pagination__page paginationColor",
      },
    };
  } else {
    options = {
      autoplay: true,
      type: "loop",
      speed: 2500,
      perPage: 3.5,
      perMove: 1,
      gap: "1vw",
      interval: 3500,
      arrows: true,
      isNavigation: true,
      resetProgress: false,
      classes: {
        pagination: "splide__pagination paginationPosition",
        page: "splide__pagination__page paginationColor",
      },
    };
  }

  return !loaded ? (
    <></>
  ) : (
    <Splide
      onActive={(Splide) => {
        Splide.Components.Autoplay.play();
      }}
      aria-label="top_works_slider"
      options={options}
    >
      {achievements.slice(0, 5).map((item) => {
        return (
          <SplideSlide key={item.create_datetime}>
            <div className="slide">
              <span
                onClick={() => navigation(`/works/${item.create_datetime}`)}
              >
                <img
                  className="slide-img"
                  src={item.thumbnail}
                  alt={item.headline}
                ></img>
              </span>
            </div>
          </SplideSlide>
        );
      })}
    </Splide>
  );
});
export default SlideShow;
