import { useEffect, useState } from "react";
import "../App.css";
import ContactBar from "../Components/ContactBar";
import Header from "../Components/Header";
import { Topics as TopicsModel } from "../models/topics";
import { listTopics } from "../services/topics";
import Linkify from "linkify-react";
import { useNavigate } from "react-router";

function Topics() {
  const navigation = useNavigate();
  const [topics, setTopics] = useState<TopicsModel[]>([]);
  const [active1Class, setActive1Class] = useState<boolean>(false);
  const animation1 = "animation1";
  const active1 = "active1";

  useEffect(() => {
    const fetch = async () => {
      const res = await listTopics();
      setTopics(res.data);
      setActive1Class(true);
    };
    fetch();
  }, []);

  return (
    <>
      <div id="container">
        <Header />
        <div className="videotop TopicsContact"></div>
        <main>
          <div>
            <div className="bar_top_line"></div>
            <p className="bar">
              <a href="./">Top</a>&emsp;&gt;&emsp;
              <a href="./topics">Topics</a>
            </p>
          </div>

          <div className="white_back_topics">
            <div
              className={active1Class ? animation1 + " " + active1 : animation1}
            >
              <div className="top_text">
                <p className="top_text_child_h2BL">Topics&emsp;</p>
                <p className="top_text_child_h3BL">お知らせ</p>
              </div>
              <div id="topics" className="topics_main_child">
                {topics.map((item) => {
                  return (
                    <p className="topics BL" key={item.create_datetime}>
                      <span
                        className="topicsText"
                        onClick={() =>
                          navigation(`/topics/${item.create_datetime}`)
                        }
                      >
                        <span className="topicsTime">
                          {item.year}.{item.month}.{item.day}
                        </span>
                        <span className="topicsDesc">{item.headline}</span>
                      </span>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>

          <ContactBar />
        </main>

        <footer>
          <small>
            Copyright&copy; <a href="./">SAMPLE COMPANY</a> All Rights Reserved.
          </small>
          <span className="pr">
            <a href="https://template-party.com/" target="_blank">
              《Web Design:Template-Party》
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}
export default Topics;
