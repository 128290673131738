import "../App.css";
import ContactBar from "../Components/ContactBar";
import Header from "../Components/Header";
import useFadeIn from "../hooks/useFadeIn";
import { mediaQuery, useMediaQuery } from "../hooks/mediaQuery";

function OurBusiness() {
  const animation1 = "animation1";
  const animation2 = "animation2";
  const animation3 = "animation3";
  const active1 = "active1";
  const active2 = "active2";
  const active3 = "active3";
  const [active1Class, setActive1Class] = useFadeIn(animation1, 1.2);
  const [active2Class, setActive2Class] = useFadeIn(animation2, 1.4);
  const [active3Class, setActive3Class] = useFadeIn(animation3, 1.6);
  const isPhone = useMediaQuery(mediaQuery.phone);

  if (isPhone) {
    return (
      <>
        <div id="container">
          <Header />

          <div className="videotop phone_top">
            <h1 className="child_copy">Our&nbsp;Business</h1>
          </div>

          <main>
            <div className="bar_top_line"></div>
            <img
              className="Child_top"
              src="images/our_business_childTop.png"
              alt="business_top"
            />

            <div>
              <div className="bar_top_line"></div>
              <p className="bar">
                <a href="./">Top</a>&emsp;&gt;&emsp;
                <a href="./our-business">Our&nbsp;Business</a>
              </p>
            </div>

            <div className="white_back_business">
              <div
                id="12business"
                className={
                  active2Class.has("12business")
                    ? animation2 + " " + active2
                    : animation2
                }
              >
                <img
                  className="left_child"
                  src="images/move_create_child.png"
                  alt="映像制作事業"
                />
                <div className="inline_right">
                  <p className="top_text_child_h2_business">
                    映像制作事業&emsp;&emsp;
                  </p>
                  <p className="top_text_child_p">
                    企画やコンセプトづくりから、撮影、録音、編集に至るまで、動画制作に携わるすべての過程をワンストップで対応します。お客様の想い・ブランディングを第一とした、クリエイティブな映像制作をお任せください。
                  </p>
                </div>
                <img
                  className="right_child"
                  src="images/HP_child.png"
                  alt="HP制作事業"
                />
                <div className="inline_left">
                  <p className="top_text_child_h2_business">
                    HP制作事業&emsp;&emsp;
                  </p>
                  <p className="top_text_child_p">
                    デザインやコーディング、その他CMS構築はもちろんのこと、徹底したヒアリングを行ったうえでのHPの企画・設計・ブランディングの提案まで。全て安心してお任せいただけるよう、お客様のご要望に合わせた実現性の高いHPを制作します。
                  </p>
                </div>
              </div>
              <div
                id="34business"
                className={
                  active3Class.has("34business")
                    ? animation3 + " " + active3
                    : animation3
                }
              >
                <img
                  className="left_child"
                  src="images/consal_child.png"
                  alt="採用コンサル事業"
                />
                <div className="inline_right">
                  <p className="top_text_child_h2_business">
                    採用コンサル事業&emsp;&emsp;
                  </p>
                  <p className="top_text_child_p">
                    採用状況のヒアリングから、戦略立案、採用課題の抽出、改善まで。採用課題の本質を捉え、多角的な観点からお客様の採用活動を見直し、採用を成功に導くための戦略設計と実行支援をトータルサポートします。
                  </p>
                </div>
                <img
                  className="right_child"
                  src="images/out_sorce_child.png"
                  alt="アウトソーシング事業"
                />
                <div className="inline_left">
                  <p className="top_text_child_h2_business">
                    アウトソーシング事業&emsp;&emsp;
                  </p>
                  <p className="top_text_child_p">
                    エントリー対応や合否連絡、説明会や面接の日程調整、リマインド連絡、その他電話やメールを用いた応募者対応まで。採用に関する一連の間接業務をスムーズに対応します。
                  </p>
                </div>
              </div>
            </div>

            <ContactBar />
          </main>

          <footer>
            <small>
              Copyright&copy; <a href="./">SAMPLE COMPANY</a> All Rights
              Reserved.
            </small>
            <span className="pr">
              <a href="https://template-party.com/" target="_blank">
                《Web Design:Template-Party》
              </a>
            </span>
          </footer>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="container">
          <Header />

          <div className="videotop phone_top">
            <h1 className="child_copy">Our&nbsp;Business</h1>
          </div>

          <main>
            <div className="bar_top_line"></div>
            <img
              className="Child_top"
              src="images/our_business_childTop.png"
              alt="business_top"
            />

            <div>
              <div className="bar_top_line"></div>
              <p className="bar">
                <a href="./">Top</a>&emsp;&gt;&emsp;
                <a href="./our-business">Our&nbsp;Business</a>
              </p>
            </div>

            <div className="white_back_business">
              <img
                id="child_move_create_img"
                className={
                  active1Class.has("child_move_create_img")
                    ? "left_child" + " " + animation1 + " " + active1
                    : "left_child" + " " + animation1
                }
                src="images/move_create_child.png"
                alt="映像制作事業"
              />
              <div className="inline_right">
                <p
                  id="child_move_create_h2"
                  className={
                    active2Class.has("child_move_create_h2")
                      ? "top_text_child_h2_business" +
                        " " +
                        animation2 +
                        " " +
                        active2
                      : "top_text_child_h2_business" + " " + animation2
                  }
                >
                  映像制作事業&emsp;&emsp;
                </p>
                <p
                  id="child_move_create_p"
                  className={
                    active3Class.has("child_move_create_p")
                      ? "top_text_child_p" + " " + animation3 + " " + active3
                      : "top_text_child_p" + " " + animation3
                  }
                >
                  企画やコンセプトづくりから、撮影、録音、編集に至るまで、動画制作に携わるすべての過程をワンストップで対応します。お客様の想い・ブランディングを第一とした、クリエイティブな映像制作をお任せください。
                </p>
              </div>
              <img
                id="child_HP_img"
                className={
                  active1Class.has("child_HP_img")
                    ? "right_child" + " " + animation1 + " " + active1
                    : "right_child" + " " + animation1
                }
                src="images/HP_child.png"
                alt="HP制作事業"
              />
              <div className="inline_left">
                <p
                  id="child_HP_h2"
                  className={
                    active2Class.has("child_HP_h2")
                      ? "top_text_child_h2_business" +
                        " " +
                        animation2 +
                        " " +
                        active2
                      : "top_text_child_h2_business" + " " + animation2
                  }
                >
                  HP制作事業&emsp;&emsp;
                </p>
                <p
                  id="child_HP_p"
                  className={
                    active3Class.has("child_HP_p")
                      ? "top_text_child_p" + " " + animation3 + " " + active3
                      : "top_text_child_p" + " " + animation3
                  }
                >
                  デザインやコーディング、その他CMS構築はもちろんのこと、徹底したヒアリングを行ったうえでのHPの企画・設計・ブランディングの提案まで。全て安心してお任せいただけるよう、お客様のご要望に合わせた実現性の高いHPを制作します。
                </p>
              </div>
              <img
                id="child_consal_img"
                className={
                  active1Class.has("child_consal_img")
                    ? "left_child" + " " + animation1 + " " + active1
                    : "left_child" + " " + animation1
                }
                src="images/consal_child.png"
                alt="採用コンサル事業"
              />
              <div className="inline_right">
                <p
                  id="child_consal_h2"
                  className={
                    active2Class.has("child_consal_h2")
                      ? "top_text_child_h2_business" +
                        " " +
                        animation2 +
                        " " +
                        active2
                      : "top_text_child_h2_business" + " " + animation2
                  }
                >
                  採用コンサル事業&emsp;&emsp;
                </p>
                <p
                  id="child_consal_p"
                  className={
                    active3Class.has("child_consal_p")
                      ? "top_text_child_p" + " " + animation3 + " " + active3
                      : "top_text_child_p" + " " + animation3
                  }
                >
                  採用状況のヒアリングから、戦略立案、採用課題の抽出、改善まで。採用課題の本質を捉え、多角的な観点からお客様の採用活動を見直し、採用を成功に導くための戦略設計と実行支援をトータルサポートします。
                </p>
              </div>
              <img
                id="child_out_sorce_img"
                className={
                  active1Class.has("child_out_sorce_img")
                    ? "right_child" + " " + animation1 + " " + active1
                    : "right_child" + " " + animation1
                }
                src="images/out_sorce_child.png"
                alt="アウトソーシング事業"
              />
              <div className="inline_left">
                <p
                  id="child_out_sorce_h2"
                  className={
                    active2Class.has("child_out_sorce_h2")
                      ? "top_text_child_h2_business" +
                        " " +
                        animation2 +
                        " " +
                        active2
                      : "top_text_child_h2_business" + " " + animation2
                  }
                >
                  アウトソーシング事業&emsp;&emsp;
                </p>
                <p
                  id="child_out_sorce_p"
                  className={
                    active3Class.has("child_out_sorce_p")
                      ? "top_text_child_p" + " " + animation3 + " " + active3
                      : "top_text_child_p" + " " + animation3
                  }
                >
                  エントリー対応や合否連絡、説明会や面接の日程調整、リマインド連絡、その他電話やメールを用いた応募者対応まで。採用に関する一連の間接業務をスムーズに対応します。
                </p>
              </div>
            </div>

            <ContactBar />
          </main>

          <footer>
            <small>
              Copyright&copy; <a href="./">SAMPLE COMPANY</a> All Rights
              Reserved.
            </small>
            <span className="pr">
              <a href="https://template-party.com/" target="_blank">
                《Web Design:Template-Party》
              </a>
            </span>
          </footer>
        </div>
      </>
    );
  }
}
export default OurBusiness;
