import { instance } from "./axios";

export async function listAchievements() {
  const response = await instance.get("/achievements");
  return response;
}

export async function getAchievements(create_datetime: string) {
  const response = await instance.get(`/achievements/${create_datetime}`);
  return response;
}
