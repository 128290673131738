import "../App.css";
import ContactBar from "../Components/ContactBar";
import Header from "../Components/Header";
import useFadeIn from "../hooks/useFadeIn";

function About() {
  const animation1 = "animation1";
  const active1 = "active1";
  const [active1Class, setActive1Class] = useFadeIn(animation1, 1.2);
  return (
    <>
      <div id="container">
        <Header />

        <div className="videotop phone_top">
          <h1 className="child_copy">About</h1>
        </div>

        <main>
          <div className="bar_top_line"></div>
          <img
            className="Child_top"
            src="images/about_childTop.jpeg"
            alt="camera"
          />

          <div>
            <div className="bar_top_line"></div>
            <p className="bar">
              <a href="./">Top</a>&emsp;&gt;&emsp;
              <a href="./About">About</a>
            </p>
          </div>

          <div
            id="white_back_about"
            className={
              active1Class.has("white_back_about")
                ? "white_back_about" + " " + animation1 + " " + active1
                : "white_back_about" + " " + animation1
            }
          >
            <div className="top_text">
              <p className="child_about">
                時代の変化と共に形を変えていく&nbsp;”採用”&nbsp;の在り方
              </p>
              <br />
              <p className="child_p_about">
                少子高齢化による人手不足の加速化、採用マーケットにおけるプレイヤーの増加によって、現在の採用活動はさらに多様化しています。
                <br />
                <br />
                「求人を掲載しても応募が増えない...」
                <br />
                「自分が求める企業の探し方がわからない...」
                <br />
                <br />
                と悩む企業・求職者は後を絶たず、
                双方にとってベストな採用活動を行うことが、これまでの採用方法では難しくなってきています。
                <br />
                ひとりひとりの影響力が大きい現代社会において、企業にとって”より適切な人材”を求職者にとって”より信頼できる会社選び”をすることは必要不可欠。
                <br />
                <br />
                Moversでは、そんな採用に悩む企業と求職者、双方の隔たりを無くし、より快適な採用活動を行うための、フロー設計、採用コンサルティング、HP制作、リクルート動画の制作など、採用課題解決までに至る全ての活動をトータルサポート。
                <br />
                <br />
                貴社にあった、最適な採用のカタチをご提供します。
              </p>
            </div>
          </div>

          <div
            id="Our_business section"
            className={
              active1Class.has("Our_business section")
                ? "Our_business section" + " " + animation1 + " " + active1
                : "Our_business section" + " " + animation1
            }
          >
            <div className="black_back">
              <div className="top_text">
                <p className="top_text_child_h2">Company&emsp;</p>
                <p className="top_text_child_h3">企業情報</p>

                <div className="company_main">
                  <p className="company">
                    会社名&emsp;&nbsp;&emsp;&nbsp;&emsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&emsp;&nbsp;株式会社Movers（英文名:Movers,inc.）&nbsp;&emsp;
                  </p>
                  <p className="company">
                    代表取締役&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&emsp;荒川&nbsp;真澄&nbsp;&nbsp;&emsp;&emsp;&emsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;
                  </p>
                  <p className="company">
                    事業内容&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;採用支援,&nbsp;採用広報物の制作&emsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;
                  </p>
                  <p className="company">
                    設立&emsp;&nbsp;&emsp;&nbsp;&emsp;&emsp;&nbsp;&emsp;&nbsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&nbsp;2022年&nbsp;7月19日&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&emsp;
                  </p>
                  <p className="company">
                    従業員数&emsp;&nbsp;&emsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;5名（役員社員,&nbsp;業務委託含む）&emsp;&emsp;&nbsp;&emsp;&nbsp;&emsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>

          <ContactBar />
        </main>

        <footer>
          <small>
            Copyright&copy; <a href="./">SAMPLE COMPANY</a> All Rights Reserved.
          </small>
          <span className="pr">
            <a href="https://template-party.com/" target="_blank">
              《Web Design:Template-Party》
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}
export default About;
