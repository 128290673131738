import "../App.css";
import "../App";

const ContactBar: React.FC = () => {
  return (
    <>
      <div className="Contact">
        <div className="white_back_contact">
          <div className="contact_main">
            <p>お問い合わせ</p>
          </div>
          <a href="../contact">
            <p className="contact_btn">contact</p>
          </a>
        </div>
      </div>
    </>
  );
};
export default ContactBar;
