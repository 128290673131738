import "../App.css";
import "../App";
import { useEffect, useState } from "react";
import { Topics } from "../models/topics";
import { getTopic } from "../services/topics";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import ContactBar from "../Components/ContactBar";
import Linkify from "linkify-react";

export default function TopicDetail() {
  const { id } = useParams<{ id: string }>();
  const [topic, setTopic] = useState<Topics>();
  useEffect(() => {
    const fetchTopics = async () => {
      if (id !== undefined) {
        const res = await getTopic(id);
        setTopic(res.data);
      }
    };
    fetchTopics();
  }, []);

  return (
    <>
      <div id="container">
        <Header />
        <div className="videotop videotopDetail"></div>

        <main>
          <div className="bar_top_line"></div>
          <p className="bar">
            <a href="../">Top</a>&emsp;&gt;&emsp;
            <a href="../Topics">Topics</a>&emsp;&gt;&emsp;
            <a className="ommision" href={String(topic?.create_datetime)}>
              {topic?.headline}
            </a>
          </p>

          <div className="topicDetail">
            <h2 className="headline">{topic?.headline}</h2>
            <div className="bar_top_line BL"></div>
            <br />
            <h3>
              {topic?.year}.{topic?.month}.{topic?.day}
            </h3>

            <br />
            <p className="description">
              <Linkify>{topic?.description}</Linkify>
            </p>
          </div>
          <ContactBar />
        </main>

        <footer>
          <small>
            Copyright&copy; <a href="../">SAMPLE COMPANY</a> All Rights
            Reserved.
          </small>
          <span className="pr">
            <a href="https://template-party.com/" target="_blank">
              《Web Design:Template-Party》
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}
