import "../App.css";
import "../App";
import { useEffect, useState } from "react";
import { Achievements } from "../models/achievements";
import { getAchievements } from "../services/achievements";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import ContactBar from "../Components/ContactBar";
import Linkify from "linkify-react";

export default function AchievementsDetail() {
  const { id } = useParams<{ id: string }>();
  const [achievements, setAchievements] = useState<Achievements>();
  useEffect(() => {
    const fetchAchievements = async () => {
      if (id !== undefined) {
        const res = await getAchievements(id);
        setAchievements(res.data);
      }
    };
    fetchAchievements();
  }, []);

  return (
    <>
      <div id="container">
        <Header />
        <div className="videotop videotopDetail"></div>

        <main>
          <div className="bar_top_line"></div>
          <p className="bar">
            <a href="../">Top</a>&emsp;&gt;&emsp;
            <a href="../Works">Works</a>&emsp;&gt;&emsp;
            <a
              className="ommision"
              href={String(achievements?.create_datetime)}
            >
              {achievements?.headline}
            </a>
          </p>

          <div className="workDetail">
            <h2 className="headline">{achievements?.headline}</h2>
            <div className="bar_top_line BL"></div>
            <br />
            <img
              src={achievements?.thumbnail}
              alt=""
            />
            <p className="description">
              <Linkify>{achievements?.description}</Linkify>
            </p>
          </div>
          <ContactBar />
        </main>

        <footer>
          <small>
            Copyright&copy; <a href="../">SAMPLE COMPANY</a> All Rights
            Reserved.
          </small>
          <span className="pr">
            <a href="https://template-party.com/" target="_blank">
              《Web Design:Template-Party》
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}
