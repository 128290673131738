import { instance } from "./axios";

export async function listTopics() {
  const response = await instance.get("/topics");
  return response;
}

export async function getTopic(create_datetime: string) {
  const response = await instance.get(`/topics/${create_datetime}`);
  return response;
}
