import { useForm, SubmitHandler } from "react-hook-form";
import "../App.css";
import ContactBar from "../Components/ContactBar";
import Header from "../Components/Header";
import { CreateContact, initialCreateContact } from "../models/contacts";
import { createContact } from "../services/contacts";

function Contact() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateContact>({ defaultValues: initialCreateContact });

  const onSubmit: SubmitHandler<CreateContact> = async (data) => {
    try {
      await createContact(data);
      reset();
      alert("問い合わせを受け付けました");
    } catch (error) {
      alert("エラーが発生しました");
    }
  };

  return (
    <>
      <div id="container" className="animation1 active1">
          <Header />

        <div className="videotop TopicsContact"></div>
        <main>
          <div className="bar_top_line"></div>
          <p className="bar">
            <a href="./">Top</a>&emsp;&gt;&emsp;
            <a href="./contact">Contact</a>
          </p>

          <div className="white_back_child_contact">
            <div className="top_text z_index_contact_phone">
              <p className="top_text_child_h2BL">Contact&emsp;</p>
              <p className="top_text_child_h3BL">お問い合わせ</p>

              <div className="contact_box">
                <form
                  className="field"
                  data-persist="garlic"
                  method="post"
                  id="mailForm"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <table className="contact_table">
                    <tr>
                      <th>
                        メールアドレス
                        <span className="must">&nbsp;※</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="email"
                          placeholder="メールアドレス"
                          {...register("mail", {
                            required: "入力してください",
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                              message: "無効なメールアドレスです",
                            },
                          })}
                        />
                      </td>
                      {!!errors.mail && <h3>{errors.mail.message}</h3>}
                    </tr>
                    <tr>
                      <th>
                        お問い合わせ内容
                        <span className="must">&nbsp;※</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="off"
                          placeholder="お問い合わせ内容"
                          {...register("description", {
                            required: "入力してください",
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                          })}
                        />
                      </td>
                      {!!errors.description && (
                        <h3>{errors.description.message}</h3>
                      )}
                    </tr>
                    <tr>
                      <th>
                        件名
                        <span className="must">&nbsp;※</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="off"
                          placeholder="件名"
                          {...register("subject", {
                            required: "入力してください",
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                          })}
                        />
                      </td>
                      {!!errors.subject && <h3>{errors.subject.message}</h3>}
                    </tr>
                    <tr>
                      <th>
                        説明
                        <span className="must">&nbsp;※</span>
                      </th>
                      <td>
                        <textarea
                          className="textarea"
                          autoComplete="off"
                          placeholder="説明"
                          {...register("explain", {
                            required: "入力してください",
                            maxLength: {
                              value: 1000,
                              message: "最大文字数を超えています",
                            },
                          })}
                        ></textarea>
                      </td>
                      {!!errors.explain && <h3>{errors.explain.message}</h3>}
                    </tr>
                    <tr>
                      <th>
                        会社名
                        <span className="must">&nbsp;※</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="organization"
                          placeholder="会社名"
                          {...register("company", {
                            required: "入力してください",
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                          })}
                        />
                      </td>
                      {!!errors.company && <h3>{errors.company.message}</h3>}
                    </tr>
                    <tr>
                      <th>
                        部署名
                        <span className="must">&nbsp;&emsp;</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="organization-title"
                          placeholder="部署名"
                          {...register("department", {
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                          })}
                        />
                      </td>
                      {!!errors.department && (
                        <h3>{errors.department.message}</h3>
                      )}
                    </tr>
                    <tr>
                      <th>
                        氏名
                        <span className="must">&nbsp;※</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="name"
                          placeholder="氏名"
                          {...register("name", {
                            required: "入力してください",
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                          })}
                        />
                      </td>
                      {!!errors.name && <h3>{errors.name.message}</h3>}
                    </tr>
                    <tr>
                      <th>
                        電話番号
                        <span className="must">&nbsp;※</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="tel-local"
                          placeholder="電話番号"
                          {...register("phone_number", {
                            required: "入力してください",
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                          })}
                        />
                      </td>
                      {!!errors.phone_number && (
                        <h3>{errors.phone_number.message}</h3>
                      )}
                    </tr>
                    <tr>
                      <th>
                        ホームページのURL
                        <span className="must">&nbsp;&emsp;</span>
                      </th>
                      <td>
                        <input
                          className="input"
                          autoComplete="url"
                          placeholder="ホームページのURL"
                          {...register("home_page", {
                            // required: "入力してください",
                            pattern: {
                              value:
                                /(https?:\/\/[\w\-\.\/\?\,\#\:\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+)/,
                              message: "無効なURLです",
                            },
                            maxLength: {
                              value: 100,
                              message: "最大文字数を超えています",
                            },
                          })}
                        />
                      </td>
                      {!!errors.home_page && (
                        <h3>{errors.home_page.message}</h3>
                      )}
                    </tr>
                  </table>
                </form>
              </div>
            </div>
            <button
              className="go"
              id="button"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              送信
            </button>
          </div>

          <ContactBar />
        </main>

        <footer>
          <small>
            Copyright&copy; <a href="./">SAMPLE COMPANY</a> All Rights Reserved.
          </small>
          <span className="pr">
            <a href="https://template-party.com/" target="_blank">
              《Web Design:Template-Party》
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}
export default Contact;
