import "./App.css";
import Top from "./pages/Top";
import About from "./pages/About";
import OurBusiness from "./pages/OurBusiness";
import Works from "./pages/Works";
import Topics from "./pages/Topics";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AchievementsDetail from "./pages/AchievementsDetail";
import TopicDetail from "./pages/TopicDetail";
import { ScrollToTop } from "./hooks/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" Component={Top} />
        <Route path="/about" Component={About} />
        <Route path="/our-business" Component={OurBusiness} />
        <Route path="/works" Component={Works} />
        <Route path="/topics" Component={Topics} />
        <Route path="/topics/:id" Component={TopicDetail} />
        <Route path="/contact" Component={Contact} />
        <Route path="/works/:id" Component={AchievementsDetail} />
      </Routes>
    </Router>
  );
}

export default App;
