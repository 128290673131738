import { CreateContact } from "../models/contacts";
import { instance } from "./axios";

export async function createContact(contact: CreateContact) {
  const formattedContact = {
    mail: contact.mail,
    description: contact.description,
    subject: contact.subject,
    explain: contact.explain || undefined,
    company: contact.company || undefined,
    department: contact.department || undefined,
    name: contact.name || undefined,
    phone_number: contact.phone_number || undefined,
    home_page: contact.home_page || undefined,
  };
  const response = await instance.post("/contacts", formattedContact);
  return response;
}
