import "../App.css";
import "../App";
import { useEffect, useState } from "react";
import Header from "../Components/Header";
import ScrollTop from "../hooks/ScrollTop";
import useFadeIn from "../hooks/useFadeIn";
import ContactBar from "../Components/ContactBar";
import { Topics as TopicsModel } from "../models/topics";
import { listTopics } from "../services/topics";
import Linkify from "linkify-react";
import { useNavigate } from "react-router";
import SlideShow from "../Components/SlideShow";

function Top() {
  const animation1 = "animation1";
  const animation2 = "animation2";
  const animation3 = "animation3";
  const animation4 = "animation4";
  const active1 = "active1";
  const active2 = "active2";
  const active3 = "active3";
  const active4 = "active4";
  const [active1Class, setActive1Class] = useFadeIn(animation1, 1.2);
  const [active2Class, setActive2Class] = useFadeIn(animation2, 1.6);
  const [active3Class, setActive3Class] = useFadeIn(animation3, 1.8);
  const [active4Class, setActive4Class] = useFadeIn(animation4, 2.0);
  const animationSlow = "animationSlow";
  const AboutImg = "About_img";
  const activeSlow = "activeSlow";
  const rotate = "rotate";
  const bgLine = "bg_Line";
  const bgLineAnime = "bg_Line_anime";
  const bglineArrow = "bg_lineArrow";
  const bglineArrowAnime = "bg_lineArrow_anime";

  const navigation = useNavigate();
  const [topics, setTopics] = useState<TopicsModel[]>([]);

  const [activeSlowClass, setActiveSlowClass] = useState<boolean>(false);
  const AnimeSlow = () => {
    const position = ScrollTop();
    const targetTop = document.querySelectorAll("." + animationSlow);
    targetTop.forEach((item) => {
      const h = item.getBoundingClientRect().top + window.pageYOffset;
      if (h - window.innerHeight / 1.5 <= position) {
        setActiveSlowClass(true);
      }
    });
  };
  const [activeRotateClass, setActiveRotateClass] = useState<boolean>(false);
  const AnimeRotate = () => {
    const position = ScrollTop();
    const targetTop = document.querySelectorAll("." + AboutImg);
    targetTop.forEach((item) => {
      const h = item.getBoundingClientRect().top + window.pageYOffset;
      if (h - window.innerHeight / 1.2 <= position) {
        setActiveRotateClass(true);
      }
    });
  };
  const [activeArrowClass, setActiveArrowClass] = useState<boolean>(false);
  const AnimeArrow = () => {
    const position = ScrollTop();
    const targetTop = document.querySelectorAll("." + bgLine);
    targetTop.forEach((item) => {
      const h = item.getBoundingClientRect().top + window.pageYOffset;
      if (h - window.innerHeight / 1.6 <= position) {
        setActiveArrowClass(true);
      }
    });
  };
  useEffect(() => {
    const fetchTopics = async () => {
      const res = await listTopics();
      setTopics(res.data);
    };
    fetchTopics();
    document.addEventListener("scroll", AnimeSlow);
    document.addEventListener("scroll", AnimeRotate);
    document.addEventListener("scroll", AnimeArrow);
    return (): void => {
      document.removeEventListener("scroll", AnimeSlow);
      document.removeEventListener("scroll", AnimeRotate);
      document.removeEventListener("scroll", AnimeArrow);
    };
  }, []);

  return (
    <>
      <div id="container" className="Htop">
        <Header />

        <div className="videotop">
          <video
            src="moves/TOPmove.mp4"
            playsInline
            autoPlay
            muted
            loop
          ></video>
          <h1 className="copy">
            あなたの出会いを
            <br />
            カタチにする。
          </h1>
        </div>

        <main>
          <div className="bg">
            <div
              className={activeArrowClass ? bgLine + " " + bgLineAnime : bgLine}
            ></div>
            <div
              className={
                activeArrowClass
                  ? bglineArrow + " " + bglineArrowAnime
                  : bglineArrow
              }
            ></div>
          </div>

          <div className="About section">
            <div
              id="white_back_div"
              className={
                active1Class.has("white_back_div")
                  ? "white_back" + " " + animation1 + " " + active1
                  : "white_back" + " " + animation1
              }
            >
              <div className="top_text">
                <a href="./About" style={{ textDecoration: "none" }}>
                  <h2>About&emsp;&emsp;&emsp;&emsp;&emsp;</h2>
                  <h3>企業情報</h3>
                </a>
                <br />
                <p className="about_txt">
                  時代の変化と共に多様化する採用活動。 <br />
                  <br />
                  Moversでは、より快適な採用活動を行うためのフロー設計、
                  採用コンサルティング、HP制作、リクルート動画の制作など、
                  採用課題解決までに至る全ての活動をトータルサポート。貴社にあった、
                  最適な採用のカタチをご提供します。
                </p>
                <img
                  className={
                    activeRotateClass ? AboutImg + " " + rotate : AboutImg
                  }
                  src="images/logo_back.png"
                  alt="about"
                />
                <img
                  className={
                    activeSlowClass
                      ? "About_logo" + " " + animationSlow + " " + activeSlow
                      : "About_logo" + " " + animationSlow
                  }
                  src="images/logo_B.png"
                  alt="about_logo"
                />
              </div>
              <div className="about_logo_phone">
                <img src="images/logo_back.png" alt="" />
              </div>
            </div>
          </div>

          <div className="Our_business section">
            <div
              id="black_back_div"
              className={
                active1Class.has("black_back_div")
                  ? "black_back" + " " + animation1 + " " + active1
                  : "black_back" + " " + animation1
              }
            >
              <div className="top_text">
                <a href="./our-business" style={{ textDecoration: "none" }}>
                  <h2 className="WH">Our&nbsp;business&emsp;</h2>
                  <h3 className="WH">事業内容</h3>
                </a>
              </div>

              <div className="business_imges">
                <div className="business_section">
                  <span
                    id="move_create_span"
                    className={
                      active1Class.has("move_create_span")
                        ? animation1 + " " + active1
                        : animation1
                    }
                  >
                    <img
                      className="move_create img_B"
                      src="images/move_create.png"
                      alt="映像制作"
                    />
                    <p className="move_create tx_B">映像制作</p>
                    <p className="move_create p_B">
                      ワンストップで映像制作を提供。お客様のブランディング重視のクリエイティブな映像制作をご依頼ください。
                    </p>
                  </span>
                  <span
                    id="HP_span"
                    className={
                      active2Class.has("HP_span")
                        ? animation2 + " " + active2
                        : animation2
                    }
                  >
                    <img
                      className="HP img_B"
                      src="images/HP.png"
                      alt="HP制作"
                    />
                    <p className="HP tx_B">HP制作</p>
                    <p className="HP p_B">
                      HP制作はデザインからCMS構築まで徹底ヒアリング後、実現性高く提案。安心してご依頼いただけます。
                    </p>
                  </span>
                  <span
                    id="consal_span"
                    className={
                      active3Class.has("consal_span")
                        ? animation3 + " " + active3
                        : animation3
                    }
                  >
                    <img
                      className="consal img_B"
                      src="images/consal.png"
                      alt="採用コンサル"
                    />
                    <p className="consal tx_B">採用コンサル</p>
                    <p className="consal p_B">
                      採用課題を抽出し、戦略設計から実行支援までトータルサポート。成功へ導く採用活動支援。
                    </p>
                  </span>
                  <span
                    id="out_sorce_span"
                    className={
                      active4Class.has("out_sorce_span")
                        ? animation4 + " " + active4
                        : animation4
                    }
                  >
                    <img
                      className="out_sorce img_B"
                      src="images/out_sorce.png"
                      alt="採用アウトソース"
                    />
                    <p className="out_sorce tx_B">採用アウトソース</p>
                    <p className="out_sorce p_B">
                      エントリーから対応、面接日程調整、リマインドなどの採用関連業務を円滑に支援。
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            id="works_div"
            className={
              active1Class.has("works_div")
                ? "Works section" + " " + animation1 + " " + active1
                : "Works section" + " " + animation1
            }
          >
            <div className="white_back_works">
              <div className="top_text works">
                <a href="./works" style={{ textDecoration: "none" }}>
                  <h2>Works&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;</h2>
                  <h3>制作実績</h3>
                </a>
              </div>
              <SlideShow />
            </div>
          </div>

          <div className="Topics section">
            <div className="black_back">
              <div
                id="topics_div"
                className={
                  active1Class.has("topics_div")
                    ? "top_text" + " " + animation1 + " " + active1
                    : "top_text" + " " + animation1
                }
              >
                <a href="./topics" style={{ textDecoration: "none" }}>
                  <h2 className="WH">
                    Topics&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;
                  </h2>
                  <h3 className="WH">お知らせ</h3>
                </a>

                <div id="topics_mains_div" className="topics_main">
                  <div id="topicsTop" className="topics_main_child">
                    {topics.slice(0, 5).map((item) => {
                      return (
                        <p className="topics" key={item.create_datetime}>
                          <span
                            className="topicsText"
                            onClick={() =>
                              navigation(`/topics/${item.create_datetime}`)
                            }
                          >
                            <span className="topicsTime">
                              {item.year}.{item.month}.{item.day}
                            </span>
                            <span className="topicsDesc">{item.headline}</span>
                          </span>
                        </p>
                      );
                    })}
                  </div>
                  <div className="topics_points">
                    <a href="../topics" style={{ textDecoration: "none" }}>
                      ・・・
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ContactBar />
        </main>

        <footer>
          <small>
            Copyright&copy; <a href="./">SAMPLE COMPANY</a> All Rights Reserved.
          </small>
          <span className="pr">
            <a href="https://template-party.com/" target="_blank">
              《Web Design:Template-Party》
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}

export default Top;
