export type CreateContact = {
  mail: string;
  description: string;
  subject: string;
  explain?: string;
  company?: string;
  department?: string;
  name?: string;
  phone_number?: string;
  home_page?: string;
};

export const initialCreateContact: CreateContact = {
  mail: "",
  description: "",
  subject: "",
  explain: undefined,
  company: undefined,
  department: undefined,
  name: undefined,
  phone_number: undefined,
  home_page: undefined,
};
