import { useEffect, useState } from "react";
import ScrollTop from "./ScrollTop";

const useFadeIn = (
  targetStr: string,
  adjustNum: number
): [Set<string>, React.Dispatch<React.SetStateAction<Set<string>>>] => {
  const [activeClass, setActiveClass] = useState<Set<string>>(new Set([]));
  const Anime = () => {
    const position = ScrollTop();
    const targetTop = document.querySelectorAll("." + targetStr);
    targetTop.forEach((item) => {
      const h = item.getBoundingClientRect().top + window.pageYOffset;
      if (h - window.innerHeight / adjustNum <= position) {
        setActiveClass((prev) => new Set(prev.add(item.id)));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("scroll", Anime);
    return (): void => {
      document.removeEventListener("scroll", Anime);
    };
  }, []);

  return [activeClass, setActiveClass];
};

export default useFadeIn;
